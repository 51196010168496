import {
  ArrowPathIcon,
  BarsArrowDownIcon,
  ChartBarIcon,
  CloudArrowUpIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  PresentationChartLineIcon,
  UserPlusIcon,
  UsersIcon,
  ListBulletIcon,
  BookOpenIcon,
  LightBulbIcon,
  DocumentChartBarIcon,
  PencilSquareIcon,
  BeakerIcon,
  CalendarDaysIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline"
import { GlobeIcon } from "@radix-ui/react-icons"
import OSINT from "@sens/web/app/(website)/osint/page"

// used at features(products) page, usecase pages
export const AllFeatures = {
  gatherData: {
    title: "Gather and structure your open data",
    description:
      "With options to link your own data or populate from public databases, you can focus on presenting the facts and communicating your research.",
    icon: BarsArrowDownIcon,
  },
  linkDataSources: {
    title: "Link data sources",
    description:
      "Link data sources with each other to create a network of information. Help your readers to understand the context of your research.",
    icon: ArrowPathIcon,
  },
  visualizeData: {
    title: "Visualize your data",
    description:
      "Create interactive charts and graphs to help your readers understand your data. Build timelines and add images and videos to your research.",
    icon: PresentationChartLineIcon,
    screenshot: "/img/screens/graph-page.png",
  },
  communicateInvestigations: {
    title: "Communicate your investigations",
    description:
      "Create a narrative to communicate your research. Use your data to tell a story and present your conclusions. Share it with ease and invite your readers to comment and contribute.",
    icon: CloudArrowUpIcon,
  },
  newsletter: {
    title: "Newsletter",
    description: "Keep your audience engaged by sending out regular updates about your latest research and findings.",
    icon: EnvelopeIcon,
  },
  personalWebsite: {
    title: "Personal website",
    description: "Build a personal website to showcase your research, publications, and achievements.",
    icon: GlobeAltIcon,
  },
  customDomains: {
    title: "Custom domains",
    description: "Use your own domain name to give your research a professional and branded appearance.",
    icon: GlobeIcon,
  },
  seoOptimization: {
    title: "SEO optimization",
    description: "Improve the visibility of your research in search engines with built-in SEO tools.",
    icon: MagnifyingGlassIcon,
  },
  getDiscovered: {
    title: "Get discovered",
    description: "Increase the reach of your research by making it easier for others to find and cite your work.",
    icon: UserPlusIcon,
  },
  analytics: {
    title: "Analytics",
    description: "Track the performance of your publications and understand your audience with detailed analytics.",
    icon: ChartBarIcon,
  },
  collaborate: {
    title: "Collaborate",
    description: "Work together with others to create and publish your findings.",
    icon: UsersIcon,
  },
} as const

// used at app header
export const AllSolutions = {
  osint: {
    name: "OSINT",
    description: "Gather and analyze open-source intelligence",
    href: "/osint",
    icon: MagnifyingGlassIcon,
  },
  researchers: {
    name: "Researchers",
    description: "Write, visualize and share in-depth research findings",
    href: "researchers",
    icon: BookOpenIcon,
  },
  experts: {
    name: "Experts",
    description: "Showcase your expertise with rich data visualizations",
    href: "/experts",
    icon: LightBulbIcon,
  },
  dataJournalism: {
    name: "Data journalism",
    description: "Craft compelling stories with robust data",
    href: "data-journalism",
    icon: DocumentChartBarIcon,
  },
  science: {
    name: "Science communicators",
    description: "Engage the public with clear and compelling scientific information",
    href: "science",
    icon: BeakerIcon,
  },
} as const

// used at app header, products page
export const AllUsecases = {
  blog: {
    name: "Professional blog",
    listType: "blog",
    description: "Publish insightful articles and analyses, build a portfolio",
    href: "/products#usecases",
    icon: PencilSquareIcon,
  },
  database: {
    name: "Public database",
    listType: "database",
    description: "Create and contribute to a shared list of data",
    href: "/products#usecases",
    icon: ListBulletIcon,
  },
  documents: {
    name: "Document repository",
    listType: "pages",
    description: "Share and collaborate on research documents",
    href: "/products#usecases",
    icon: DocumentTextIcon,
  },
  timeline: {
    name: "Timeline",
    listType: "timeline",
    description: "Create and share interactive timelines",
    href: "/products#usecases",
    icon: CalendarDaysIcon,
  },
  presentation: {
    name: "Presentation",
    listType: "presentation",
    description: "Create and share presentations",
    href: "/products#usecases",
    icon: PresentationChartLineIcon,
  },
} as const
