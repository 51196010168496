import { AppRouter } from "@sens/web/server/router"
import { createTRPCClient, httpBatchLink, loggerLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"
import memoize from "lodash/memoize"
import superjson from "superjson"
import { getBaseUrl } from "./domain/url"
export const transformer = superjson

const logger = loggerLink({
  enabled: (opts) =>
    (process.env.NODE_ENV === "development" && typeof window !== "undefined") ||
    (opts.direction === "down" && opts.result instanceof Error),
})

export const trpc = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn()
        await opts.queryClient.invalidateQueries()
      },
    },
  },
})
export const getTrpcReactClient = memoize(() =>
  trpc.createClient({
    links: [
      logger,
      httpBatchLink({
        url: `${getBaseUrl()}/api/trpc`,
        transformer,
      }),
    ],
  })
)

// vanilla client without react goodies
export const getTrpcClient = memoize(() =>
  createTRPCClient<AppRouter>({
    links: [
      logger,
      httpBatchLink({
        url: `${getBaseUrl()}/api/trpc`,
        transformer,
      }),
    ],
  })
)

// export const trpcx = experimental_createTRPCNextAppDirClient<AppRouter>({
//   config() {
//     return {
//       links: [
//         httpBatchLink({
//           transformer,
//           url: `${getBaseUrl()}/api/trpc`,
//           async headers() {
//             return {
//               // authorization: getAuthCookie(),
//             }
//           },
//         }),
//       ],
//     }
//   },
// })
//
// export const trpc = createTRPCNext<AppRouter>({
//   transformer,
//   config(opts) {
//     return {
//       links: [
//         httpBatchLink({
//           transformer,
//           /**
//            * If you want to use SSR, you need to use the server's full URL
//            * @link https://trpc.io/docs/v11/ssr
//            **/
//           url: `${getBaseUrl()}/api/trpc`,
//           // You can pass any HTTP headers you wish here
//           async headers() {
//             return {
//               // authorization: getAuthCookie(),
//             }
//           },
//         }),
//       ],
//     }
//   },
//   /**
//    * @link https://trpc.io/docs/v11/ssr
//    **/
//   ssr: false,
// })
