"use client"

import {
  ListBulletIcon,
  PlayCircleIcon,
  PhoneIcon,
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import Link from "next/link"
import React, { Fragment, useState } from "react"
import { usePathname, useRouter } from "next/navigation"
import { trpc } from "@sens/web/lib/trpc"
import { noConflict } from "lodash"
import clsx from "clsx"
import {
  Dialog,
  Disclosure,
  Popover,
  Transition,
  PopoverGroup,
  PopoverButton,
  PopoverPanel,
  DisclosureButton,
  DisclosurePanel,
  DialogPanel,
} from "@headlessui/react"
import { SensLogo } from "./sensLogo"
import { AllSolutions, AllUsecases } from "./home/feature/allFeatures"

type Props = {
  className?: string
}

const solutions = [
  AllSolutions.osint,
  AllSolutions.researchers,
  AllSolutions.experts,
  AllSolutions.dataJournalism,
  AllSolutions.science,
]

const usecases = [
  AllUsecases.blog,
  AllUsecases.database,
  AllUsecases.documents,
  AllUsecases.timeline,
  AllUsecases.presentation,
]

const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
]

const resources = [
  { name: "Docs", href: "/docs" },
  { name: "Community", href: "https://discord.gg/HwB5J3XgQG" },
  // { name: "Events", href: "#" },
  // { name: "Security", href: "#" },
]

const company = [
  { name: "About us", href: "/about" },
  // { name: "Careers", href: "#" },
  // { name: "Docs", href: "/docs" },
  { name: "Contact", href: "/contact" },
  { name: "Community", href: "https://discord.gg/HwB5J3XgQG" },
]

export const HeaderWebsite: React.FC<Props> = ({ className }) => {
  // const pathname = usePathname()

  // let activeRoute = "/"
  // if (pathname?.includes("/products")) {
  //   activeRoute = "products"
  // } else if (pathname?.includes("/app/profile")) {
  //   activeRoute = "profile"
  // } else if (pathname?.includes("/app/mail")) {
  //   activeRoute = "mail"
  // } else if (pathname?.includes("/app/settings")) {
  //   activeRoute = "settings"
  // }

  // const navigation = [
  //   { name: "", href: "/app", current: activeRoute === "app", icon: null },
  //   {
  //     name: "Products & Solutions",
  //     href: "/products",
  //     current: activeRoute === "products",
  //     icon: null,
  //   },
  //   {
  //     name: "Experts",
  //     href: "/experts",
  //     current: activeRoute === "experts",
  //     icon: null,
  //   },
  //   {
  //     name: "Readers",
  //     href: "/readers",
  //     current: activeRoute === "readers",
  //     icon: null,
  //   },
  //   {
  //     name: "Resources",
  //     href: "/docs",
  //     current: activeRoute === "docs",
  //     icon: null,
  //   },
  //   {
  //     name: "Pricing & Plans",
  //     href: "/plans",
  //     current: activeRoute === "plans",
  //     icon: null,
  //   },
  //   {
  //     name: "Company",
  //     href: "/company",
  //     current: activeRoute === "plans",
  //     icon: null,
  //   },
  // ]

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
      {/* <ul className="bg-base-100 rounded-box text-base lg:flex lg:space-x-4 lg:py-2">
        {navigation.map((item) => (
          <li key={item.name}>
            <Link
              href={item.href}
              className={clsx(
                item.current
                  ? "bg-gray-100 text-gray-900  hover:bg-gray-100"
                  : "text-gray-900 hover:bg-gray-100 hover:text-gray-900",
                "inline-flex items-center rounded-sm py-2 px-3 gap-1"
              )}
            >
              {item.icon}
              {item.name}
            </Link>
          </li>
        ))}
      </ul> */}
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <PopoverGroup className="hidden lg:flex lg:gap-x-12">
        {/* FEATURES / PRODUCT */}
        <a
          href="/products"
          className="text-base font-medium leading-6 text-gray-900 hover:text-gray-600 transition-colors"
        >
          Products
        </a>
        {/* <Popover className="relative">
          <PopoverButton className="flex items-center gap-x-1 text-base font-medium leading-6 text-gray-900 focus:border-0 focus:ring-0">
            Product
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
              <div className="p-4">
                {products.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                  >
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" aria-hidden="true" />
                    </div>
                    <div className="flex-auto">
                      <a href={item.href} className="block font-semibold text-gray-900">
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                  >
                    <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    {item.name}
                  </a>
                ))}
              </div>
            </PopoverPanel>
          </Transition>
        </Popover> */}

        {/* SOLUTIONS / USE CASES */}
        <Popover className="relative">
          <PopoverButton className="flex items-center gap-x-1 text-base font-medium leading-6 text-gray-900 outline-none hover:text-gray-600 transition-colors">
            Solutions
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-2xl overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
              <div className="p-4 flex">
                <div>
                  <h2 className="p-4 font-semibold">Customers</h2>
                  {solutions.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div>
                  <h2 className="p-4 font-semibold">Use case</h2>
                  {usecases.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                  >
                    <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    {item.name}
                  </a>
                ))}
              </div> */}
            </PopoverPanel>
          </Transition>
        </Popover>

        {/* <a href="#" className="text-base font-medium leading-6 text-gray-900">
          Features
        </a> */}
        {/* <a href="#" className="text-base font-medium leading-6 text-gray-900">
          Resources
        </a> */}

        <Popover className="relative">
          <PopoverButton className="flex items-center gap-x-1 text-base font-medium leading-6 text-gray-900 outline-none hover:text-gray-600 transition-colors">
            Resources
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
              {resources.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </PopoverPanel>
          </Transition>
        </Popover>

        {/* <a href="#" className="text-base font-medium leading-6 text-gray-900">
          Plans & Pricing
        </a> */}

        <Popover className="relative">
          <PopoverButton className="flex items-center gap-x-1 text-base font-medium leading-6 text-gray-900 outline-none hover:text-gray-600 transition-colors">
            Company
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
              {company.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </PopoverPanel>
          </Transition>
        </Popover>
      </PopoverGroup>
      {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div> */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-1000 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <SensLogo className="w-36" />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="/products"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Products
                </a>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Solutions
                        <ChevronDownIcon
                          className={clsx(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        <h2 className="p-4 font-semibold">Customers</h2>
                        {[...solutions].map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                        <h2 className="p-4 font-semibold">Use case</h2>
                        {[...usecases].map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Resources
                        <ChevronDownIcon
                          className={clsx(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {resources.map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                {/* <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Plans & Pricing
                </a> */}

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Company
                        <ChevronDownIcon
                          className={clsx(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {company.map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              </div>
              {/* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  )
}
