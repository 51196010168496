"use client"

import { useState } from "react"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { getTrpcReactClient, trpc } from "../lib/trpc"
import { signOut } from "next-auth/react"
import { useRouter } from "next/navigation"

export const useTrpcClient = () => {
  const router = useRouter()

  // tRPC
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            // handle a query error here
            console.log(error.name, error.message)
            if (error.message === "UNAUTHORIZED") {
              // TEMPORARY: log out user
              // REMOVE ME after transition to new open-next 3
              console.log("Logging out user")
              signOut().then(() => router.push("/auth/signin"))
            }
          },
        }),
      })
  )
  const [trpcClient] = useState(() => getTrpcReactClient())

  return { queryClient, trpcClient }
}
