import { SlugType, VisualizationType, WithId } from "@sens/core/db/client"
import { IUser } from "@sens/core/db/profile"
import { BASE_URL, IS_PRODUCTION } from "../config"
import { ProfileType } from "./list/list"

export type WithSlug = {
  slug?: string
}

export type ListWithIdAndSlug = WithId & WithSlug
export type ListWithIdAndSlugAndOwner = ListWithIdAndSlug & { owner: IUser }
export type ItemWithIdAndSlug = WithId & WithSlug
export type ProfileWithIdAndSlug = WithId & WithSlug & { customDomain?: string }

export { getPublicAssetUrl } from "@sens/core/domain/url"

export function getBaseUrl() {
  // client side
  if (typeof window !== "undefined") return window.location.origin

  // server side
  if (BASE_URL) return BASE_URL

  // assume localhost
  return `http://127.0.0.1:${process.env.PORT ?? 3020}`
}

export const getProfilePublicUrl = (user: ProfileWithIdAndSlug) => {
  // TODO: use subdomain
  return `/u/${user.slug || user.id}`
}

export const getListPublicViewUrl = (list: ListWithIdAndSlug) => {
  return `/l/${list.slug || list.id}`
}

// MANAGE (/app)
// base manage lists url
// these should match in list/edit/[id]/layout.tsx
export const getListEditUrl = () => "/app/list/edit"
// edit list
export const getListEditListUrl = (list: ListWithIdAndSlug) => getListEditUrl() + `/${list.id}`
// edit list visualizations
export const getListEditVisualizationsUrl = (list: ListWithIdAndSlug) => getListEditUrl() + `/${list.id}/visual`
// edit list share
export const getListEditShareUrl = (list: ListWithIdAndSlug) => getListEditUrl() + `/${list.id}/share`
// edit list configuration
export const getListEditConfigurationUrl = (list: ListWithIdAndSlug) => getListEditUrl() + `/${list.id}/configuration`
// edit list analytics
export const getListEditAnalyticsUrl = (list: ListWithIdAndSlug) => getListEditUrl() + `/${list.id}/analytics`
// edit list collaborators
export const getListEditCollaboratorsUrl = (list: ListWithIdAndSlug) => getListEditUrl() + `/${list.id}/collaborators`

// SHARE URLS
export const getListShareUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) => {
  const path = `/share/l/${list.slug || list.id}`

  // if owner has custom domain, use that
  if (IS_PRODUCTION && owner.customDomain) {
    // if (owner.customDomain) {
    return `https://${owner.customDomain}${path}`
  }

  // otherwise use relative path on the same domain
  return getBaseUrl() + path
}

// Share embed url
export const getListShareEmbedUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/embed"

// Share profile url
export const getProfileShareUrl = (owner: ProfileWithIdAndSlug) => {
  const path = getProfilePublicUrl(owner)

  // if owner has custom domain, use that
  if (IS_PRODUCTION && owner.customDomain) {
    return `https://${owner.customDomain}${path}`
  }

  // otherwise use relative path on the same domain
  return getBaseUrl() + path
}

// Share Visualizations url
// export const getListShareVisualizationsUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
//   getListShareUrl(list, owner) + "/visual"

// "back" link - go to homepage
export const getHomeLink = (profile?: ProfileType) => {
  return profile?.homepageUrl || "/"
}

export const getHomepageUrl = (profile?: ProfileType) => {
  return profile?.homepageUrl
}

export const getListSubscribeFormUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/subscribe"
export const getListSubscribeFormEmbedUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/subscribe/embed"

export const getListSubscribeFormMiniUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/subscribe-mini"
export const getListSubscribeFormMiniEmbedUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/subscribe-mini/embed"

export const getListPublicAddItemUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/add"
export const getListPublicAddEmbedItemUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/add/embed"

export const getListPublicEmbedItemUrl = (list: ListWithIdAndSlug, owner: ProfileWithIdAndSlug) =>
  getListShareUrl(list, owner) + "/embed"
export const getListPublicDetailItemUrl = (
  list: ListWithIdAndSlug,
  item: ItemWithIdAndSlug,
  owner: ProfileWithIdAndSlug
) => getListShareUrl(list, owner) + "/detail" + `/${item.slug || item.id}`
export const getListPublicDetailItemEmbedUrl = (
  list: ListWithIdAndSlug,
  item: ItemWithIdAndSlug,
  owner: ProfileWithIdAndSlug
) => {
  return getListShareUrl(list, owner) + "/detail" + `/${item.slug || item.id}/embed`
}

export const getListVisualizationUrl = (
  list: ListWithIdAndSlug,
  visualization: Pick<VisualizationType, "id">,
  owner: ProfileWithIdAndSlug
) => getListShareUrl(list, owner) + "/visual" + `/${visualization.id}`

export const getListPublicDetailItemShareUrl = (
  list: ListWithIdAndSlug,
  item: ItemWithIdAndSlug,
  owner: ProfileWithIdAndSlug
) => getListPublicDetailItemUrl(list, item, owner)

export const getShortUrl = (slug: SlugType) => `${getBaseUrl()}/s/${slug.slug}`
