import React from "react"
import { trpc } from "../lib/trpc"
import { ProfilePrivate } from "../lib/domain/list/list"
import { useSession } from "next-auth/react"

export type CurrentProfileContext = {
  profile?: ProfilePrivate
  isReady: boolean
}

export const CurrentProfileContext = React.createContext<CurrentProfileContext>({ isReady: false })

export const CurrentProfileProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { status } = useSession()
  const profile = trpc.getProfilePrivate.useQuery(undefined, { enabled: status === "authenticated" })
  return (
    <CurrentProfileContext.Provider
      value={{ profile: profile.data, isReady: !profile.isLoading && status === "authenticated" }}
    >
      {children}
    </CurrentProfileContext.Provider>
  )
}

export const useCurrentProfile = () => {
  const context = React.useContext(CurrentProfileContext)
  if (context === undefined) {
    throw new Error("useCurrentProfile must be used within a CurrentProfileProvider")
  }
  return context
}
