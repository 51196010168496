import Link from "next/link"

type Props = {
  className?: string
}

export const SensLogo: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <Link href="/" className="flex items-center">
        <div className="pr-2">
          <img className="inline-block w-6 align-middle stroke-current" src="/img/sens_center_gr.svg" alt="" />
        </div>
        <div>
          <img className="inline-block pb-1 w-32 stroke-current" src="/img/sens_center_black3.svg" alt="" />
        </div>
      </Link>
    </div>
  )
}
