"use client"

import { signOut } from "next-auth/react"
import React from "react"

export const LogoutButton: React.FC = () => {
  const handleLogout = React.useCallback(async () => {
    await signOut()
  }, [])

  return (
    <button className="btn btn-ghost" onClick={handleLogout}>
      Sign out
    </button>
  )
}
