"use client"

import { Session } from "next-auth"
import { SessionProvider } from "next-auth/react"
import { useState } from "react"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { getTrpcReactClient, trpc } from "../lib/trpc"
import { CurrentProfileProvider } from "../context/currentUserContext"
import { useTrpcClient } from "../providers/trpc"

interface AppProvidersProps {
  session?: Session | null
  children: React.ReactNode
}

// Context providers for client-side use
export const AppProviders: React.FC<AppProvidersProps> = ({ children, session }) => {
  const { queryClient, trpcClient } = useTrpcClient()

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      {/* <MathJaxContext> */}
      <QueryClientProvider client={queryClient}>
        <SessionProvider session={session} refetchOnWindowFocus>
          <CurrentProfileProvider>{children}</CurrentProfileProvider>
        </SessionProvider>
      </QueryClientProvider>
      {/* </MathJaxContext> */}
    </trpc.Provider>
  )
}
