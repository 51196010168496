import { AssetPublic } from "../type/asset"

export const BASE_URL = process.env.BASE_URL || process.env.NEXT_PUBLIC_BASE_URL || process.env.WEB_URL
const IS_LOCAL = process.env.NEXT_PUBLIC_IS_LOCAL || process.env.IS_LOCAL

export const getPublicAssetUrl = (asset: Pick<AssetPublic, "s3Key" | "category" | "isPublic">) => {
  if (!asset.s3Key) return null
  if (!asset.isPublic) return null

  if (
    asset.s3Key.startsWith("imageFile/") ||
    asset.category === "imageFile" ||
    asset.category === "rteImage" ||
    asset.category === "visualizationPreview"
  ) {
    return getPublicAssetUrlForS3Key(asset.s3Key)
  } else {
    console.warn("Asset is public but not an image file; not using CDN", asset)
  }
  return null
}

export const getPublicAssetUrlForS3Key = (s3Key: string) => {
  // we can use CloudFront if we're not in local dev
  if (!IS_LOCAL && BASE_URL) {
    // must be a behavior specified in "additionalBehaviors" in stacks/web.ts
    return `${BASE_URL}/${s3Key}`
  } else {
    // local dev hack: append the key to the public bucket url
    const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL
    if (!bucketUrl) {
      console.error("No public bucket URL found in environment variables")
      return null
    }
    return new URL(s3Key, bucketUrl).toString()
  }
}
