"use client"
import { useIsFetching } from "@tanstack/react-query"
import Image from "next/image"

const GlobalLoader = () => {
  const isFetching = useIsFetching()

  return (
    <div className="fixed z-10 bottom-4 right-4">
      {isFetching ? (
        <Image
          width={64}
          height={64}
          className="rounded-full"
          src="/img/otterCircle.gif"
          alt="loading"
          priority={false}
        />
      ) : null}
    </div>
  )
}

export default GlobalLoader
