import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.23.6_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-_7muul4d5clrxd3lwpgrmtm774q/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/Users/cyber/dev/sens/web/src/app/appProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWebsite"] */ "/Users/cyber/dev/sens/web/src/component/elements/headerWebsite.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoutButton"] */ "/Users/cyber/dev/sens/web/src/component/elements/logoutButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/cyber/dev/sens/web/src/component/GlobalLoader.tsx");
;
import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/web/src/styles/globals.css");
